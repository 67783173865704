import Vue from 'vue';
import ECharts from 'vue-echarts'
import AOS from "aos";
// https://github.com/ecomfe/vue-echarts

// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/pie';

// register component to use
Vue.component('v-chart', ECharts)


$(function(){

  // Initi AOS
  function aos_init() {
    AOS.init({
      duration: 1000,
      once: true
    });
  }

  const chartColor = [
    '#477890','#4C86A8','#A6ABA1','#FFCF99','#C4A381',
    '#F0A38B','#E0777D','#8E3B46','#472835','#001524',
    '#9CBDCE','#A4C3D6','#D2D5D0','#FFE7CC','#E2D1C1',
    '#F7D0C4','#EFBABD','#D1919A','#B78097','#129CFF',
  ];
  
  // default options
  const default_option = {
    color: chartColor,
    // toolbox: {
    //   feature: {
    //     saveAsImage: { show: true, title: "Save as Image" }
    //   }
    // },
  }

  new Vue({
    el: '#home_report_chart',
    data: {
      day_s: 'YYYY/MM',
      all_tag_pageviews: [],  // 總體屬性關注度分布
      atp_options: {},
      select_tag_name: "",
      tag_top_10: [],  // 同屬性關注度分布TOP10
      all_tag_top_tags: {},
      acp_options: {},  // 通路關注度分布
      top_brands: [],  // 美妝品牌關注度排行榜
      top_products: [],  // 產品關注度排行榜
      show_all_brands: false,
      toggle_brand_text: "點開看更多 ↓",
      show_all_products: false,
      toggle_product_text: "點開看更多 ↓",
    },
    mounted: function() {
      let that = this;

      $.ajax({
        url: `${location.href}get_home_report`,
        type: 'GET',
        dataType: 'json',
      })
      .done(function(data) {
        that.day_s = data.day_s,
        that.all_tag_pageviews = data.all_tag_pageviews;
        that.atp_options = Object.assign({
          grid: {
            containLabel: true
          },
          series: [{
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              formatter: '{b}\n{d}%',
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: "#ffffff",
            },
            data: data.all_tag_pageviews,
          }],
        }, default_option);

        that.all_tag_top_tags = data.all_tag_top_tags;
        that.select_tag_name = data.all_tag_pageviews[0].name;
        that.tag_top_10 = data.all_tag_top_tags[data.all_tag_pageviews[0].name];

        // that.acp_options = Object.assign({
        //   grid: {
        //     containLabel: true
        //   },
        //   series: [{
        //     type: 'pie',
        //     silent: true,
        //     label: {
        //       formatter: '{b}\n{d}%',
        //     },
        //     itemStyle: {
        //       borderWidth: 2,
        //       borderColor: "#ffffff",
        //     },
        //     data: data.all_channel_pageviews,
        //   }],
        // }, default_option);
        that.top_brands = data.top_brands;
        that.top_products = data.top_products;
      }).always(function() {
        // preloader close
        if ($('#preloader').length) {
          $('#preloader').delay(100).fadeOut('slow', function() {
            $(this).remove();
          });
          aos_init();
        }
      });
    },
    methods: {
      onClick: function(el) {
        let click_name = el.data.name;
        if (el.data.selected) {
          return;
        }
        $.each(this.all_tag_pageviews, function(index, value) {
          if (value.name == click_name) {
            value.selected = true;
          } else {
            value.selected = false;
          }
        });

        this.select_tag_name = click_name;
        this.tag_top_10 = this.all_tag_top_tags[click_name];

        return;
      },
      toggleBrandList: function() {
        this.show_all_brands = !this.show_all_brands;
        if (this.show_all_brands) {
          this.toggle_brand_text = "收起清單 ↑";
        } else {
          this.toggle_brand_text = "點開看更多 ↓";
        }
      },
      toggleProductList: function() {
        this.show_all_products = !this.show_all_products;
        if (this.show_all_products) {
          this.toggle_product_text = "收起清單 ↑";
        } else {
          this.toggle_product_text = "點開看更多 ↓";
        }
      },
    }
  });
});

